
import TheDeleteDocumentModal from "@/components/documents/TheDeleteDocumentModal.vue";
import TheViewDocumentModal from "@/components/documents/TheViewDocumentModal.vue";
import BaseModal from "@/components/shared/modal/BaseModal.vue";
import { runStoreAction, runStoreMutation } from "@/shared/helpers/store.helpers";
import { useStore } from "@/shared/useHelpers";
import { StoreModules } from "@/store/store-modules.enum";
import { StoreActions } from "@/store/store.actions";
import { StoreMutations } from "@/store/store.mutations";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, ref } from "@vue/composition-api";

export default defineComponent({
  name: "TheDocumentButtons",
  components: { BaseModal, TheDeleteDocumentModal, TheViewDocumentModal },
  props: {
    documentId: {
      type: String || null,
      required: true,
    },
    uploaderId: {
      type: Number,
      required: true,
    },
    documentFileType: {
      type: String || undefined,
      required: false,
    },
    courseName: {
      type: String,
      required: false,
    },
    fileName: {
      type: String,
      reguired: true,
    },
    fileDate: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const store = useStore<StoreState>();
    const customerId = computed(() => store.state.customer.customer?.id);
    const showDeleteModal = ref(false);
    const showViewDocumentModal = ref(false);

    function customer() {
      if (customerId.value === props.uploaderId) {
        return true;
      }
      return false;
    }
    function handleDownload() {
      runStoreAction(store, StoreModules.File, StoreActions.FileActions.DownloadFile, props);
    }
    function handleView() {
      runStoreMutation(store, StoreModules.File, StoreMutations.FileMutations.SET_FILE, props);
      showViewDocumentModal.value = true;
    }

    return {
      customer,
      handleDownload,
      handleView,
      showDeleteModal,
      showViewDocumentModal,
    };
  },
});
