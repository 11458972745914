
import TheDeleteDocumentModal from "@/components/documents/TheDeleteDocumentModal.vue";
import TheViewFile from "@/components/documents/TheViewFile.vue";

import { runStoreAction } from "@/shared/helpers/store.helpers";
import { useStore } from "@/shared/useHelpers";
import { StoreModules } from "@/store/store-modules.enum";
import { StoreActions } from "@/store/store.actions";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, ref } from "@vue/composition-api";

export default defineComponent({
  name: "TheViewDocumentModal",
  emits: ["close"],
  components: {
    TheViewFile,
    TheDeleteDocumentModal,
  },
  props: {
    fileId: { type: Number, required: true },
  },
  setup(props) {
    const store = useStore<StoreState>();
    const showDeleteModal = ref(false);
    const filePreview = computed(() => store.state.file.filePreview);
    const file = computed(() => store.state.file.file);
    runStoreAction(store, StoreModules.File, StoreActions.FileActions.ViewFile, {
      fileId: props.fileId,
    });

    function handleDownload() {
      runStoreAction(store, StoreModules.File, StoreActions.FileActions.DownloadFile, file.value);
    }
    return {
      showDeleteModal,
      filePreview,
      file,
      handleDownload,
    };
  },
});
