export enum DocuSigningStatus {
  NOT_SENTVALUE = "ikke sent",
  FAILED_SENDING_TO_MIDDLEWAREVALUE = "klargjøres for signering",
  SENDING_TO_MIDDLEWAREVALUE = "klargjøres for signering",
  RECEIVED_BY_MIDDLEWAREVALUE = "klargjøres for signering",
  SENDING_TO_SIGNICATVALUE = "klargjøres for signering",
  FAILED_SENDING_TO_SIGNICATVALUE = "klargjøres for signering",
  SENT_TO_SIGNICATVALUE = "klargjøres for signering",
  REGISTERED_NOT_SIGNEDVALUE = "ikke signert",
  SIGNEDVALUE = "signert",
  DOWNLOADEDVALUE = "signert",
  DOWNLOAD_FAILEDVALUE = "signert",
  CANCELLEDVALUE = "avslått",
  EXPIREDVALUE = "fristen har utløpt",
  _ = "ukjent status",
}
