var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.filePreview ? _c('TheViewFile', {
    attrs: {
      "file": _vm.file,
      "preview": _vm.filePreview
    },
    on: {
      "close": function ($event) {
        return _vm.$emit('close');
      }
    }
  }) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }