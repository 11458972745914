
import { runStoreAction } from "@/shared/helpers/store.helpers";
import { useStore } from "@/shared/useHelpers";
import { StoreModules } from "@/store/store-modules.enum";
import { StoreActions } from "@/store/store.actions";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "TheDeleteDocumentModal",
  setup(props, context) {
    const store = useStore<StoreState>();
    const fetchCourseFiles = computed(() => store.state.file.courses);
    const fetchFile = computed(() => store.state.file.file);

    function handleDelete() {
      runStoreAction(store, StoreModules.File, StoreActions.FileActions.DeleteFile, fetchFile.value.documentId);
      context.emit("close");
    }

    return {
      fetchCourseFiles,
      fetchFile,
      handleDelete,
    };
  },
});
