
import { api } from "@/api/api";
import { ApiGetSigningFileDto } from "@/api/generated/Api";
import BaseInfoField from "@/components/shared/field/BaseInfoField.vue";
import { LoadingType } from "@/shared/enums/loading-type.enum";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { formatDateTime } from "@/shared/helpers/dateHelpers";
import { getStatusColor, getStatusIcon } from "@/shared/helpers/documentHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { getInitialModalData, useOpenModal } from "@/shared/helpers/modalHelpers";
import { capitalize } from "@/shared/helpers/textHelpers";
import { PropType, defineComponent, ref } from "@vue/composition-api";
import BaseConfirmModalForm from "../shared/modal/BaseConfirmModalForm.vue";
import BaseModal from "../shared/modal/BaseModal.vue";

export default defineComponent({
  name: "DocumentsReadyForSigning",
  components: { BaseInfoField, BaseConfirmModalForm, BaseModal },
  props: {
    documentsWithSigning: {
      type: Array as PropType<ApiGetSigningFileDto[]>,
      required: true,
    },
    courseSearch: {
      type: Number,
      required: false,
    },
  },
  setup(props) {
    const documentsReadyToBeSigned = props.documentsWithSigning;
    const rejectModalData = ref(getInitialModalData());

    const currentPanels = ref<number[]>(
      documentsReadyToBeSigned.length > 0 ? [...documentsReadyToBeSigned.keys()] : []
    );

    const getPanelHeader = (item: ApiGetSigningFileDto) => {
      let header = "";
      header = `${item.unsignedFileName} | ${capitalize(item.signStatus || "Ukjent")}`;

      let documentName = item?.unsignedFileId || item?.unsignedFileName || "Dokument";
      header = `${header} | ${documentName}`;

      return header;
    };

    const rejectDocument = (file: any) => {
      globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
        api.signature.cancelSigning(file.existingItemId);
      });
    };

    return {
      documentsReadyToBeSigned,
      currentPanels,
      getStatusColor,
      getStatusIcon,
      getPanelHeader,
      formatDateTime,
      rejectDocument,
      showRejectModal: useOpenModal(ModalType.Custom, "Avbryt", rejectModalData),
      rejectModalData,
    };
  },
});
