var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "title font-weight-light text-center",
    staticStyle: {
      "align-self": "center"
    }
  }, [_vm.showUploadDocumentModal ? _c('BaseModal', [_c('TheUploadDocumentModal', {
    attrs: {
      "fileList": _vm.list,
      "placeNames": _vm.placeNames,
      "selectedCourseId": _vm.selectedCourseId
    },
    on: {
      "close": function ($event) {
        _vm.showUploadDocumentModal = false;
      },
      "getFilesAddPlacename": _vm.getFilesAddPlacename
    }
  })], 1) : _vm._e(), _c('v-btn', {
    staticClass: "text--primary",
    attrs: {
      "color": "white"
    },
    on: {
      "click": function ($event) {
        _vm.showUploadDocumentModal = true;
      }
    }
  }, [_c('v-icon', [_vm._v(" mdi-upload ")]), _vm._v(" Last opp dokument ")], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }