var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-container', [_c('v-card-title', [_c('span', {
    staticClass: "headline"
  }, [_vm._v(_vm._s(_vm.headline))])]), _c('v-card-text', [_vm._t("content")], 2), _c('v-card-actions', [_vm._t("actions", function () {
    return [_c('v-spacer'), _c('v-btn', {
      attrs: {
        "color": "grey darken-3",
        "primary": "",
        "text": ""
      },
      on: {
        "close": function ($event) {
          return _vm.$emit('close');
        }
      }
    }, [_vm._v("Avbryt")]), _c('v-btn', {
      attrs: {
        "color": "primary",
        "data-cy": "Save_Edit_Button",
        "text": ""
      },
      on: {
        "click": _vm.submitForm
      }
    }, [_vm._v("Lagre ")])];
  })], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }