
import { ApiGetCourseRelatedFileDto } from "@/api/generated/Api";
import TheUploadDocumentModal from "@/components/documents/TheUploadDocumentModal.vue";
import BaseModal from "@/components/shared/modal/BaseModal.vue";
import { PropType, defineComponent } from "@vue/composition-api";

interface ComponentData {
  showUploadDocumentModal: boolean;
}

export interface ApiShortAreaDto {
  id: number;
  placeName: string | null | undefined;
}

export default defineComponent({
  name: "TheUploadDocumentModalContainer",
  components: { BaseModal, TheUploadDocumentModal },
  emits: ["getFilesAddPlacename"],
  props: {
    list: {
      type: Array as PropType<ApiGetCourseRelatedFileDto[]>,
      required: true,
    },
    placeNames: {
      type: Array as PropType<ApiShortAreaDto[]>,
      required: true,
    },
    selectedCourseId: {
      type: Number,
      required: false,
    },
  },
  // eslint-disable-next-line no-empty-pattern
  setup({}, { emit }) {
    const getFilesAddPlacename = () => {
      emit("getFilesAddPlacename");
    };

    return {
      getFilesAddPlacename,
    };
  },
  data: function (): ComponentData {
    return {
      showUploadDocumentModal: false,
    };
  },
});
