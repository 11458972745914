
import { defineComponent } from "@vue/composition-api";
// @ts-ignore TODO : Typescriptiong.
import pdf from "vue-pdf";

export default defineComponent({
  name: "TheViewFile",
  props: {
    file: {
      type: Object,
      required: true,
    },
    preview: {
      type: String,
      required: true,
    },
  },
  components: {
    pdf,
  },
});
