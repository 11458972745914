
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "TheDocumentsModalForm",
  props: {
    headline: {
      type: String,
      default: "",
    },
  },
  methods: {
    submitForm() {
      this.$emit("submit");
      this.$emit("close");
    },
    close() {
      this.$emit("close");
    },
  },
});
