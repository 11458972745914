import { DocuSigningStatus } from "../enums/documentSigningStatus";

const getIconAndColor = (status: string | undefined | null) => {
  let obj = {
    color: "grey",
    icon: "mdi-close-circle",
  };

  if (status === DocuSigningStatus.DOWNLOAD_FAILEDVALUE) {
    obj = {
      color: "red",
      icon: "mdi-close-circle",
    };
  }

  if (status === DocuSigningStatus.REGISTERED_NOT_SIGNEDVALUE) {
    obj = {
      color: "orange",
      icon: "mdi-pen",
    };
  }

  if (status === DocuSigningStatus.FAILED_SENDING_TO_MIDDLEWAREVALUE) {
    obj = {
      color: "primary",
      icon: "mdi-send",
    };
  }

  if (status === DocuSigningStatus.SIGNEDVALUE) {
    obj = {
      color: "green",
      icon: "mdi-check-circle",
    };
  }

  if (status === DocuSigningStatus.EXPIREDVALUE) {
    obj = {
      color: "amber",
      icon: "mdi-clock-time-four",
    };
  }

  if (status === DocuSigningStatus.CANCELLEDVALUE) {
    obj = {
      color: "amber",
      icon: "mdi-close-circle",
    };
  }

  return obj;
};

export const getStatusColor = (status: string | undefined | null) => {
  const { color } = getIconAndColor(status);
  return color;
};

export const getStatusIcon = (status: string | undefined | null) => {
  const { icon } = getIconAndColor(status);
  return icon;
};
