
import { api } from "@/api/api";
import {
  ApiGetCourseDto,
  ApiGetCourseParticipantDto,
  ApiGetCourseRelatedFileDto,
  ApiGetSigningFileDto,
} from "@/api/generated/Api";
import TheDocumentButtons from "@/components/documents/TheDocumentButtons.vue";
import TheUploadDocumentModalContainer, {
  ApiShortAreaDto,
} from "@/components/documents/TheUploadDocumentModalContainer.vue";
import BaseHeaderList from "@/components/shared/header/BaseHeaderList.vue";
import { OwnerType } from "@/shared/enums/ownerType.enum";
import { formatDateTime } from "@/shared/helpers/dateHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { computed, defineComponent, onMounted, ref } from "@vue/composition-api";
import BaseLayout from "../shared/layout/BaseLayout.vue";
import TheDocumentsTable from "./TheDocumentsTable.vue";
import { isMobile } from "@/shared/helpers/displayHelpers";
import DocumentsReadyForSigning from "@/components/documents/DocumentsReadyForSigning.vue";

enum DocumentTabs {
  Documents = "documents",
  Signing = "signing",
}

export default defineComponent({
  name: "TheDocumentList",
  components: {
    BaseHeaderList,
    TheUploadDocumentModalContainer,
    TheDocumentButtons,
    TheDocumentsTable,
    BaseLayout,
    DocumentsReadyForSigning,
  },

  setup() {
    const courseList = ref<ApiGetCourseParticipantDto[]>();
    const courseListFiltered = ref<ApiGetCourseDto[]>();
    const fetchedFiles = ref<ApiGetCourseRelatedFileDto[]>();
    const placeNames = ref<ApiShortAreaDto[]>([]);
    const pairedPlaceNames = ref<any[]>([]);
    const courseListSearch = ref<any>([]);
    const fileList = ref<ApiGetCourseRelatedFileDto[]>([]);
    const search = ref<number>();
    const currentTab = ref(DocumentTabs.Documents);
    const hasDocumentsReadyForSigning = ref(true);
    const signingDocuments = ref<ApiGetSigningFileDto[]>([]);

    const getAllPlaceNames = async () => {
      return globalLoadingWrapper({ blocking: true }, async () => {
        placeNames.value = (await api.area.getAreasAsync()).data.map((x) => {
          return { id: x.id, placeName: x.place };
        });
      });
    };

    const pairPlaceNameAndCourse = () => {
      return globalLoadingWrapper({ blocking: true }, async () => {
        courseList.value = (await api.course.getCourseParticipantAndCoursesByCurrentUserAsync()).data;

        getCourseSearchList();

        courseListFiltered.value = courseList.value
          ?.reduce<ApiGetCourseDto[]>((courses, { course }) => {
            if (!course) {
              return courses;
            }
            return [...courses, course];
          }, [])
          .filter((x) => x.status !== "avsluttet");

        if (!courseListFiltered.value) {
          return;
        }

        const cL = courseListFiltered.value;
        const pN = placeNames.value;
        let i = cL.length;

        while (i--) {
          let x = placeNames.value.length;

          while (x--) {
            if (pN[x].id === cL[i].courseLocation?.areaId) {
              pairedPlaceNames.value.push({ id: cL[i].id, placeName: pN[x].placeName });
            }
          }
        }
      });
    };

    const pairPlaceName = (ownerId: number | undefined) => {
      if (!pairedPlaceNames.value) {
        return;
      }

      let y = pairedPlaceNames.value.length;
      let matchedName;

      while (y--) {
        if (pairedPlaceNames.value[y].id === ownerId) {
          matchedName =
            pairedPlaceNames.value[y].placeName[0].toUpperCase() +
            pairedPlaceNames.value[y].placeName.slice(1).toLowerCase();
        }
      }

      return matchedName;
    };

    const getFilesAddPlacename = () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        const response = (await api.file.getParticipantFilesAsync()).data;

        fileList.value = response || [];

        fetchedFiles.value = response
          .map((file) => {
            const courseId = file.fileOwners?.find((fo) => fo.ownerType === OwnerType.Course)?.ownerId;

            return {
              ...file,
              id: file.id,
              tableId: file?.id?.toString().concat("_", courseId?.toString() || ""),
              insertedFormatted: formatDateTime(file.inserted),
              courseId: courseId,
              courseName: file.fileOwners?.find((fo) => fo.ownerType === OwnerType.Course)?.ownerName,
              placeName: pairPlaceName(file.fileOwners?.find((x) => x.ownerType === OwnerType.Course)?.ownerId) || "-",
            };
          })
          .reverse();
      });
    };

    const getSignatureDocuments = async () => {
      const response = await api.file.getParticipantSignatureFiles();
      signingDocuments.value = response.data.filter((x) => x.signStatus !== "avslått");
      hasDocumentsReadyForSigning.value = signingDocuments.value.length > 0;
    };

    const getCourseSearchList = () => {
      if (!courseList) {
        return;
      }

      courseListSearch.value = courseList.value
        ?.map((x) => {
          return { id: x.courseId, courseName: `${x.course?.externalTitle} - (${x.courseId})` };
        })
        .sort((a, b) => a.courseName?.localeCompare(b.courseName ?? "") ?? 0);
    };

    onMounted(() => {
      globalLoadingWrapper({ blocking: true }, async () => {
        await getAllPlaceNames();
        await pairPlaceNameAndCourse();
        getFilesAddPlacename();
        getSignatureDocuments();
      });
    });

    return {
      placeNames,
      fetchedFiles,
      getFilesAddPlacename,
      hasDocumentsReadyForSigning,
      courseListSearch,
      courseList,
      fileList,
      search,
      isMobile,
      currentTab,
      DocumentTabs,
      numberOfUnsignedDocuments: computed(() => signingDocuments.value.filter((x) => !x.isSigned).length),
      signingDocuments,
    };
  },

  computed: {
    message() {
      if (this.$route.name === "coursesDocuments") {
        return "Studiefiler";
      }
      return "Personlige filer";
    },
  },

  methods: {
    handleClick(value: any) {
      this.$router.push(`/document/${value.id}`);
    },
    handleCourseSelect() {
      this.$router.push({
        name: "courseDocuments",
        params: {
          id: (this as any).course.value,
        },
      });
    },
  },
});
