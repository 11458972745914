var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('TheDocumentsModalForm', {
    attrs: {
      "headline": "Last opp dokument"
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('v-form', {
          ref: "form",
          attrs: {
            "lazy-validation": ""
          },
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v;
            },
            expression: "valid"
          }
        }, [_c('v-row', [_c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('v-select', {
          attrs: {
            "items": _vm.courseListFiltered,
            "item-text": "externalTitle",
            "label": "Velg kurs/studie",
            "single-line": "",
            "rules": [_vm.validateNotEmpty]
          },
          model: {
            value: _vm.course,
            callback: function ($$v) {
              _vm.course = $$v;
            },
            expression: "course"
          }
        })], 1), _c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "Velg dokumentkategori",
            "items": _vm.fileCategoriesSelector,
            "item-text": "text",
            "rules": [_vm.validateNotEmpty]
          },
          model: {
            value: _vm.fileData.selectedCategory,
            callback: function ($$v) {
              _vm.$set(_vm.fileData, "selectedCategory", $$v);
            },
            expression: "fileData.selectedCategory"
          }
        }), _vm.fileData.selectedCategory === _vm.DocumentCategory.PoliceCertificate ? _c('v-alert', {
          attrs: {
            "outlined": "",
            "dense": "",
            "type": "warning",
            "icon": "mdi-account-tie-hat"
          }
        }, [_vm._v(" Politiattest er kun aktuelt for kurs og studier med praksis. ")]) : _vm._e()], 1), _c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('v-text-field', {
          attrs: {
            "label": "Dokumentnavn",
            "name": "description",
            "counter": "50",
            "type": "text",
            "hide-details": "",
            "required": ""
          },
          model: {
            value: _vm.fileData.fileDescription,
            callback: function ($$v) {
              _vm.$set(_vm.fileData, "fileDescription", $$v);
            },
            expression: "fileData.fileDescription"
          }
        })], 1), _c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('v-file-input', {
          attrs: {
            "rules": [_vm.validateNotEmpty],
            "show-size": "",
            "counter": "",
            "chips": "",
            "label": "Last opp dokument"
          },
          model: {
            value: _vm.fileData.file,
            callback: function ($$v) {
              _vm.$set(_vm.fileData, "file", $$v);
            },
            expression: "fileData.file"
          }
        })], 1)], 1)], 1), _c('v-dialog', {
          attrs: {
            "persistent": "",
            "max-width": "290"
          },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v;
            },
            expression: "dialog"
          }
        }, [_c('v-card', [_c('v-card-title', {
          staticClass: "text-h5"
        }, [_vm._v("Er du sikker?")]), _c('v-card-text', [_vm._v("Du har allerede en fil med navn " + _vm._s(_vm.fileName) + " i kategori: " + _vm._s(_vm.fileData.selectedCategory) + ", ønsker du å laste opp en til?")]), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
          attrs: {
            "color": "green darken-1",
            "text": ""
          },
          on: {
            "click": _vm.rerunUpload
          }
        }, [_vm._v(" Bekreft ")]), _c('v-btn', {
          attrs: {
            "color": "green darken-1",
            "text": ""
          },
          on: {
            "click": function ($event) {
              _vm.dialog = false;
            }
          }
        }, [_vm._v(" Avbryt ")])], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('v-spacer'), _c('v-btn', {
          attrs: {
            "color": "grey darken-3",
            "primary": "",
            "text": ""
          },
          on: {
            "click": function ($event) {
              return _vm.$emit('close');
            }
          }
        }, [_vm._v("Avbryt")]), _c('v-btn', {
          attrs: {
            "disabled": _vm.loading,
            "loading": _vm.loading,
            "color": "primary",
            "data-cy": "Save_Edit_Button",
            "text": ""
          },
          on: {
            "click": _vm.uploadCourseFiles
          }
        }, [_vm._v(" Lagre ")])];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }