var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "elevation-0",
    attrs: {
      "rounded": "lg"
    }
  }, [_c('v-card-title', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showTitle,
      expression: "showTitle"
    }],
    class: _vm.titleClass
  }, [_vm._t("title")], 2), _c('v-spacer'), _c('v-data-table', _vm._g(_vm._b({
    class: {
      'scrollable-table': _vm.scrollable
    },
    attrs: {
      "fixed-header": "",
      "hide-default-footer": _vm.hidePaginatorFooter,
      "items-per-page": 30,
      "footer-props": {
        itemsPerPageOptions: [10, 30, 50, -1]
      }
    },
    scopedSlots: _vm._u([{
      key: "body.prepend",
      fn: function () {
        return [_vm._t("prepend")];
      },
      proxy: true
    }, _vm._l(_vm.remainingSlots, function (slot) {
      return {
        key: slot,
        fn: function (props) {
          return [_vm._t(slot, null, null, props)];
        }
      };
    }), {
      key: "item.actions",
      fn: function (props) {
        return [_vm._t("actions", null, null, props)];
      }
    }, _vm.$scopedSlots.groupHeader ? {
      key: "group.header",
      fn: function (props) {
        return [_c('td', {
          staticClass: "align-center",
          attrs: {
            "colspan": props.headers.length
          }
        }, [_c('v-btn', {
          staticClass: "mr-2",
          attrs: {
            "icon": "",
            "small": ""
          },
          on: {
            "click": props.toggle
          }
        }, [_c('v-icon', [_vm._v(_vm._s(props.isOpen ? "mdi-minus" : "mdi-plus"))])], 1), _vm._t("groupHeader", null, null, props), _c('v-btn', {
          attrs: {
            "icon": "",
            "small": ""
          },
          on: {
            "click": props.remove
          }
        }, [_c('v-icon', [_vm._v(" mdi-close ")])], 1)], 2)];
      }
    } : null], null, true)
  }, 'v-data-table', _vm.$attrs, false), _vm.$listeners)), _vm._t("after-table")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }