
import TheDocumentButtons from "@/components/documents/TheDocumentButtons.vue";
import { DocumentUserTableColumns } from "@/shared/utils/tableHeaders";
import { PropType, defineComponent } from "@vue/composition-api";
import BaseTable from "@/components/shared/table/BaseTable.vue";
import { ApiGetCourseRelatedFileDto } from "@/api/generated/Api";
import { capitalize } from "@/shared/helpers/textHelpers";

export default defineComponent({
  name: "TheDocumentsTable",
  props: {
    fileList: {
      type: Array as PropType<ApiGetCourseRelatedFileDto[]>,
      required: true,
    },
    search: {
      type: Number,
      required: false,
    },
  },
  setup() {
    return {
      headers: DocumentUserTableColumns,
      capitalize,
    };
  },
  components: { TheDocumentButtons, BaseTable },
});
