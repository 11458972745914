var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_vm.documentId === 'null' ? _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "data-cy": "downloadButton",
            "icon": "",
            "color": "primary"
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          staticClass: "py-0",
          attrs: {
            "disabled": true
          }
        }, [_vm._v(" mdi-download ")])], 1)];
      }
    }], null, false, 1115594011)
  }, [_vm._v(" Politiattest er behandlet og oppbevares ikke lenger av AOF iht. lovverk. ")]) : _c('v-btn', {
    attrs: {
      "data-cy": "downloadButton",
      "icon": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.handleDownload();
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-download")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }