export enum DocumentCategory {
  TradeCertificate = "Fagbrev",
  Diploma = "Vitnemål/Kursbevis",
  CV = "CV/Følgebrev",
  Certificate = "Attest",
  PoliceCertificate = "Politiattest",
  Confirmation = "Bekreftelse",
  Contract = "Kontrakt",
  ContractUnsigned = "Kontrakt (usignert)",
  Economy = "Økonomi",
  CourseParticipantExpense = "Kursdeltaker utleggskvittering",
  Regulation = "Kursbestemmelse",
  WorkingHourVismaExport = "Arbeidstimer (Visma Lønn eksport)",
  WorkingHourInvoiceBasis = "Arbeidstimer (fakturagrunnlag)",
  FacilitationGrantApplicationFile = "Tilretteleggingstilskudd",
  Other = "Annet",
}
