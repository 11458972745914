var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-container', [_c('v-card-text', [_c('pdf', {
    attrs: {
      "src": _vm.preview
    }
  })], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "grey darken-3",
      "primary": "",
      "text": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v("Ok")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }