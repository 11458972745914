var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseLayout', {
    attrs: {
      "displayTabs": ""
    },
    scopedSlots: _vm._u([{
      key: "tabs",
      fn: function () {
        return [_c('v-tabs', {
          model: {
            value: _vm.currentTab,
            callback: function ($$v) {
              _vm.currentTab = $$v;
            },
            expression: "currentTab"
          }
        }, [_c('v-tab', {
          attrs: {
            "href": ("#" + (_vm.DocumentTabs.Documents))
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-file-document-multiple")]), _vm._v(" Dokumenter ")], 1), _c('v-tab', {
          attrs: {
            "href": ("#" + (_vm.DocumentTabs.Signing))
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-file-sign")]), _c('v-badge', {
          attrs: {
            "value": _vm.numberOfUnsignedDocuments,
            "content": _vm.numberOfUnsignedDocuments,
            "color": "error"
          }
        }, [_vm._v(" Signering ")])], 1)], 1)];
      },
      proxy: true
    }, !_vm.isMobile ? {
      key: "title",
      fn: function () {
        return [_vm._v(_vm._s(_vm.message))];
      },
      proxy: true
    } : null, {
      key: "actions",
      fn: function () {
        return [_c('v-autocomplete', {
          staticClass: "mt-5 pt-1 mr-5",
          attrs: {
            "items": _vm.courseListSearch,
            "item-text": "courseName",
            "item-value": "id",
            "label": "Velg Kurs/studie",
            "single-line": "",
            "clear-icon": "mdi-eraser",
            "dense": "",
            "clearable": ""
          },
          model: {
            value: _vm.search,
            callback: function ($$v) {
              _vm.search = $$v;
            },
            expression: "search"
          }
        }), _c('TheUploadDocumentModalContainer', {
          attrs: {
            "placeNames": _vm.placeNames,
            "list": _vm.fileList,
            "selectedCourseId": _vm.search
          },
          on: {
            "getFilesAddPlacename": _vm.getFilesAddPlacename
          }
        })];
      },
      proxy: true
    }], null, true)
  }, [[_c('v-tabs-items', {
    attrs: {
      "value": _vm.currentTab
    }
  }, [_c('v-tab-item', {
    attrs: {
      "value": _vm.DocumentTabs.Documents
    }
  }, [_c('v-row', {
    staticClass: "ma-1"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": _vm.hasDocumentsReadyForSigning ? '6' : '12'
    }
  }, [_c('v-card', {
    staticClass: "pa-2",
    attrs: {
      "outlined": "",
      "elevation": "4",
      "tile": ""
    }
  }, [_c('v-card-title', [_c('v-icon', {
    attrs: {
      "large": "",
      "left": ""
    }
  }, [_vm._v("mdi-information")]), _vm._v(" Informasjon ")], 1), _c('p', [_vm._v(" Her finner du alle dokumenter som er lastet opp av deg, eller av administrasjon i AOF. Du kan laste opp dokumenter på ditt kurs/studie over å velge et spesifikt kurs/studie i nedtrekksmenyen. Du kan sortere dokumentene ved å trykke på kolonneoverskriftene. ")])], 1)], 1), _vm.hasDocumentsReadyForSigning ? _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "6"
    }
  }, [_c('v-card', {
    staticClass: "pa-2",
    attrs: {
      "outlined": "",
      "elevation": "4",
      "tile": ""
    }
  }, [_c('v-card-title', [_c('v-icon', {
    attrs: {
      "large": "",
      "left": ""
    }
  }, [_vm._v("mdi-file-sign")]), _vm._v(" Signering "), _c('v-spacer'), _vm.numberOfUnsignedDocuments > 0 ? _c('v-badge', {
    staticClass: "float-right",
    attrs: {
      "offset-x": 18,
      "content": _vm.numberOfUnsignedDocuments,
      "color": "error"
    }
  }) : _vm._e()], 1), _c('p', [_vm._v("Her finner du alle dokumentene som krever BankID signatur eller avslag i MinSide.")]), _c('v-btn', {
    staticClass: "float-right mb-3",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.currentTab = _vm.DocumentTabs.Signing;
      }
    }
  }, [_vm._v(" Gå til signering ")])], 1)], 1) : _vm._e()], 1), _c('v-row', [_c('v-col', [_vm.fetchedFiles ? _c('TheDocumentsTable', {
    attrs: {
      "fileList": _vm.fetchedFiles,
      "search": _vm.search
    }
  }) : _vm._e()], 1)], 1)], 1), _c('v-tab-item', {
    attrs: {
      "value": _vm.DocumentTabs.Signing
    }
  }, [_c('DocumentsReadyForSigning', {
    attrs: {
      "documentsWithSigning": _vm.signingDocuments,
      "courseSearch": _vm.search
    }
  })], 1)], 1)]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }