var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.rejectModalData.showModal ? _c('BaseModal', {
    attrs: {
      "maxWidth": "400px"
    }
  }, [_c('BaseConfirmModalForm', {
    attrs: {
      "modalBaseData": _vm.rejectModalData
    },
    on: {
      "close": function ($event) {
        _vm.rejectModalData.showModal = false;
      },
      "submit": _vm.rejectDocument
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "color": "red"
    }
  }, [_vm._v("mdi-flag")]), _vm._v(" Ved å trykke bekreft vil du avslå tilbudet om studieplass. Er du sikker på dette? ")], 1)], 1) : _vm._e(), _vm.documentsReadyToBeSigned.length === 0 ? _c('p', {
    staticClass: "pa-4 text-center"
  }, [_vm._v("Ingen dokumenter med signeringsstatus")]) : _c('v-expansion-panels', {
    attrs: {
      "multiple": "",
      "accordion": "",
      "focusable": ""
    },
    model: {
      value: _vm.currentPanels,
      callback: function ($$v) {
        _vm.currentPanels = $$v;
      },
      expression: "currentPanels"
    }
  }, _vm._l(_vm.documentsReadyToBeSigned, function (item, i) {
    return _c('v-expansion-panel', {
      key: i
    }, [_c('v-expansion-panel-header', [_c('div', [_c('v-icon', {
      attrs: {
        "left": "",
        "color": _vm.getStatusColor(item.signStatus)
      }
    }, [_vm._v(_vm._s(_vm.getStatusIcon(item.signStatus)))]), _vm._v(" " + _vm._s(_vm.getPanelHeader(item)) + " ")], 1)]), _c('v-expansion-panel-content', [_c('v-row', [_c('v-col', {
      attrs: {
        "cols": "12",
        "xs": "12",
        "sm": "6",
        "md": "6",
        "lg": "4"
      }
    }, [_c('BaseInfoField', {
      attrs: {
        "value": _vm.formatDateTime(item.inserted),
        "label": "Dato sendt til signering"
      }
    })], 1), item.signatureDeadline ? _c('v-col', {
      attrs: {
        "cols": "12",
        "xs": "12",
        "sm": "6",
        "md": "6",
        "lg": "4"
      }
    }, [_c('BaseInfoField', {
      attrs: {
        "value": _vm.formatDateTime(item.signatureDeadline || ''),
        "label": "Frist for signering"
      }
    })], 1) : _vm._e()], 1), _c('v-row', {
      staticClass: "mt-2",
      attrs: {
        "justify": "end"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "12",
        "xs": "12",
        "md": "2"
      }
    }, [item.isCancellable ? _c('v-btn', {
      staticClass: "mr-2",
      on: {
        "click": function () {
          return _vm.showRejectModal(item);
        }
      }
    }, [_c('v-icon', [_vm._v(" mdi-close-thick ")]), _vm._v(" Avslå ")], 1) : _vm._e()], 1)], 1)], 1)], 1);
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }