var render = function () {
  var _vm$search;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseTable', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.fileList,
      "search": (_vm$search = _vm.search) === null || _vm$search === void 0 ? void 0 : _vm$search.toString(),
      "item-key": "tableId",
      "sort-by": "inserted",
      "sort-desc": true
    },
    scopedSlots: _vm._u([{
      key: "item.originalFileName",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('span', {
          staticClass: "font-weight-medium"
        }, [_vm._v(_vm._s(item.originalFileName))])];
      }
    }, {
      key: "item.inserted",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.insertedFormatted) + " ")];
      }
    }, {
      key: "item.description",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(_vm.capitalize(item.description || "")) + " ")];
      }
    }, {
      key: "actions",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('TheDocumentButtons', {
          attrs: {
            "documentId": ("" + (item.id || null)),
            "uploaderId": item.insertedBy,
            "documentFileType": item.mimeType,
            "courseName": item.courseName,
            "fileName": item.originalFileName,
            "fileDate": item.inserted
          }
        })];
      }
    }], null, true)
  });
}
var staticRenderFns = []

export { render, staticRenderFns }