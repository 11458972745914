var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "data-cy": "baseModalForm"
    }
  }, [_c('v-container', {
    staticClass: "pa-0"
  }, [_vm.headline ? _c('v-card-title', [_vm._t("headline", function () {
    return [_c('span', {
      staticClass: "headline",
      attrs: {
        "data-cy": "headline"
      }
    }, [_vm._v(_vm._s(_vm.headline))])];
  })], 2) : _vm._e(), _c('v-card-text', [_vm._t("default")], 2), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "data-cy": "cancelButton",
      "color": "",
      "text": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.cancelButtonText) + " ")]), _c('v-btn', {
    attrs: {
      "data-cy": "submitButton",
      "color": "error",
      "text": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('submit', _vm.modalBaseData);
      }
    }
  }, [_vm._v(_vm._s(_vm.submitButtonText))])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }