
import { api } from "@/api/api";
import { ApiGetCourseDto, ApiGetCourseParticipantDto, ApiGetCourseRelatedFileDto } from "@/api/generated/Api";
import TheDocumentsModalForm from "@/components/documents/TheDocumentsModalForm.vue";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { openNotification } from "@/shared/helpers/store.helpers";
import { validateNotEmpty } from "@/shared/helpers/validationHelpers";
import { LocalFileData } from "@/shared/interfaces/localFileData.interfaces";
import { useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { PropType, defineComponent, onMounted, ref, watch } from "@vue/composition-api";
import Vue from "vue";
import { ApiShortAreaDto } from "./TheUploadDocumentModalContainer.vue";
import { DocumentCategory } from "@/shared/enums/document.enum";

const getInitialFileData = (): LocalFileData => ({
  file: undefined,
  selectedCategory: "",
  fileDescription: "",
});
const FILE_TYPE_WHITELIST = ["application/pdf", "image/png", "image/jpeg"];
export default defineComponent({
  name: "TheUploadDocumentModal",
  components: { TheDocumentsModalForm },

  props: {
    placeNames: {
      type: Array as PropType<ApiShortAreaDto[]>,
      required: false,
    },
    fileList: {
      type: Array as PropType<ApiGetCourseRelatedFileDto[]>,
      required: true,
    },
    selectedCourseId: {
      type: Number,
      required: false,
    },
  },
  emits: ["getFilesAddPlacename"],
  setup({ placeNames, fileList, selectedCourseId }, { emit, refs }) {
    const store = useStore<StoreState>();
    const valid = ref(true);
    const loading = ref(false);
    const course = ref<ApiGetCourseDto>();
    const courseList = ref<ApiGetCourseParticipantDto[]>();
    const courseListFiltered = ref<ApiGetCourseDto[]>();
    const file = ref<File>();
    const fileCategoriesSelector = ref<string[]>();
    const fileData = ref(getInitialFileData());
    const dialog = ref(false);
    const fileAmountSelectedCategory = ref(0);
    const bypassConfirmation = ref(false);
    const fileName = ref("");

    const fetchCourseFileCategories = async () => {
      const response = await api.file.getMinSideFileDefaultOption();
      if (!response.data.categories) {
        return;
      }
      fileCategoriesSelector.value = response.data.categories.sort((a, b) => a.localeCompare(b ?? "") ?? 0);
    };

    const fetchCoursesAssignedToUser = async () => {
      const response = await api.course.getCourseParticipantAndCoursesByCurrentUserAsync();
      if (!response.data) {
        return;
      }
      courseList.value = response.data;

      courseListFiltered.value = courseList.value
        ?.reduce<ApiGetCourseDto[]>((courses, { course }) => {
          if (!course) {
            return courses;
          }
          return [...courses, course];
        }, [])
        .filter((x: any) => x.status !== "avsluttet");

      const getOccurrence = (array: any, value: string | null) => {
        const trimmedValue = value?.trim().toLowerCase();
        let count = 0;
        array.forEach((v: any) => v === trimmedValue && count++);
        return count;
      };

      const courseNames = courseListFiltered.value.map((x) => {
        return x.externalTitle?.trim().toLowerCase() || x.courseName?.trim().toLowerCase();
      });

      let i = courseListFiltered.value.length;

      while (i--) {
        const courseList = courseListFiltered.value;

        if (!courseList[i].courseName) {
          return;
        }

        courseList[i].externalTitle ||= courseList[i].courseName;

        const occurence = getOccurrence(courseNames, courseList[i].externalTitle as string);

        if (occurence > 1) {
          if (courseList[i].courseLocation?.areaId) {
            if (!placeNames) {
              return;
            }

            let x = placeNames?.length;
            let matchedPlaceName;

            while (x--) {
              if (placeNames[x].id === courseList[i].courseLocation?.areaId) {
                matchedPlaceName = placeNames[x].placeName;
              }
            }

            if (!matchedPlaceName) {
              return;
            }

            courseList[i].externalTitle = `${courseList[i].externalTitle} - ${
              matchedPlaceName?.[0].toUpperCase() + matchedPlaceName.slice(1).toLowerCase()
            }`;
          }
        }

        courseList[i].externalTitle = `${courseList[i].id} - ${courseList[i].externalTitle}`;
      }

      courseListFiltered.value.reverse();
      if (selectedCourseId) {
        course.value = courseListFiltered.value.find((x) => x.id === selectedCourseId);
      }
    };

    watch(fileData.value, () => {
      bypassConfirmation.value = false;
    });

    const ILLEGAL_CHARS_REGEX = /[^\s\w.@-]+/g;
    const sanitizeFilename = (filename: string) => filename.replace(ILLEGAL_CHARS_REGEX, "-");

    const uploadCourseFiles = async () => {
      if (!course.value || !fileData.value.selectedCategory) {
        openNotification(store, NotificationItemType.Error, "Du må velge kurs og kategori", 3000);
        return;
      }

      const isValid = (refs.form as Vue & { validate: () => boolean }).validate();

      if (!fileData.value.file || !isValid) {
        return;
      }

      const type = fileData.value.file.type;

      if (!FILE_TYPE_WHITELIST.includes(type)) {
        openNotification(
          store,
          NotificationItemType.Error,
          "Ugyldig filformat, vennligst velg en fil av typen PDF, PNG eller JPEG",
          3000
        );
        return;
      }

      const uploadFile = new File([fileData.value.file], sanitizeFilename(fileData.value.file.name), {
        type,
      });

      const data = {
        File: uploadFile,
        Description: fileData.value.fileDescription,
        Category: fileData.value.selectedCategory,
      };

      if (!fileList) {
        return;
      }

      if (!course.value) {
        return;
      }

      if (!courseListFiltered.value) {
        return;
      }

      const selectedCourse =
        courseListFiltered.value.find((x) => x.externalTitle === course.value) ||
        courseListFiltered.value.find((x) => x.id === selectedCourseId);

      if (!selectedCourse?.id) {
        return;
      }

      const filesInSelectedCategory = fileList
        .filter((x) => {
          if (x.fileOwners) {
            if (x.fileOwners[0].ownerId === selectedCourse.id) {
              return true;
            }
          }
          return false;
        })
        .filter((y) => y.category === fileData.value.selectedCategory);

      const fileListNamesInSelectedCategory = filesInSelectedCategory.map((x) => x.originalFileName);

      if (!bypassConfirmation.value) {
        if (fileListNamesInSelectedCategory.includes(data.File.name)) {
          fileName.value = data.File.name;
          dialog.value = true;
          return;
        }
      }

      loading.value = true;

      await api.file.createCourseParticipantFileAsync(selectedCourse?.id, data, { format: "blob" });
      openNotification(store, NotificationItemType.Success, "Vellykket opplastning", 3000);

      emit("getFilesAddPlacename");
      loading.value = false;
      emit("close");
    };

    const rerunUpload = () => {
      bypassConfirmation.value = true;
      dialog.value = false;
      uploadCourseFiles();
    };

    onMounted(async () => {
      await fetchCourseFileCategories();
      await fetchCoursesAssignedToUser();
    });

    return {
      valid,
      loading,
      courseListFiltered,
      course,
      file,
      uploadCourseFiles,
      fileCategoriesSelector,
      validateNotEmpty,
      fileData,
      dialog,
      fileAmountSelectedCategory,
      rerunUpload,
      fileName,
      DocumentCategory,
    };
  },
});
