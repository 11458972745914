import { AttendanceMutations } from "@/store/modules/attendance/attendance.mutations.enum";
import { CustomerMutations } from "@/store/modules/customer/customer.mutations.enum";
import { ExpensesMutations } from "@/store/modules/expenses/expenses.mutations.enum";
import { FileMutations } from "@/store/modules/file/file.mutations.enum";
import { GradesMutations } from "@/store/modules/grades/grades.mutations.enum";
import { HocMutations } from "@/store/modules/hoc/hoc.mutations.enum";
import { HoursMutations } from "@/store/modules/hours/hours.mutations.enum";
import { MessagesMutations } from "@/store/modules/messages/messages.mutations.enum";
import { NotificationsMutations } from "@/store/modules/notifications/notifications.mutations.enum";
import { RegisterMutations } from "@/store/modules/register/register.mutations.enum";
import { SigninMutations } from "@/store/modules/signin/signin.mutations.enum";
import { TestMutations } from "@/store/modules/test/test.mutations.enum";
import { TodosMutations } from "@/store/modules/todos/todos.mutations.enum";
import { GlobalMutations } from "@/store/modules/global/global.mutations.enum";

export const StoreMutations = Object.freeze({
  GlobalMutations,
  AttendanceMutations,
  CustomerMutations,
  ExpensesMutations,
  FileMutations,
  GradesMutations,
  HocMutations,
  HoursMutations,
  MessagesMutations,
  NotificationsMutations,
  RegisterMutations,
  SigninMutations,
  TestMutations,
  TodosMutations,
});

export type StoreMutationsType =
  | GlobalMutations
  | AttendanceMutations
  | CustomerMutations
  | ExpensesMutations
  | FileMutations
  | GradesMutations
  | HocMutations
  | HoursMutations
  | MessagesMutations
  | NotificationsMutations
  | RegisterMutations
  | SigninMutations
  | TestMutations
  | TodosMutations;
